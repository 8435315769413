// Translated
// Migrated
<template lang="pug">
NuxtLayout(v-if="category" :name="setLayout")
  .pb-5
    .py-2
      .my-5(v-if="category.preamble_html")
        .category-preamble(
          :data-translation="category?.preamble_html_translationkey"
          v-html="category.preamble_html"
        )
      .mt-5(v-else)

      template(v-if="category.special_videointroduction?.length")
        LazyMediaLogoCard(
          v-for="(videoIntroduction, idx) in specialVideoIntroductions"
          :key="idx"
          :logo="videoIntroduction.logo"
          :title="videoIntroduction.quote"
          :title-by="videoIntroduction.quote_by"
          :text="videoIntroduction.text"
          :media="videoIntroduction.media"
        )
      LazyCategoryFirstTripCard(v-else-if="firstTrip", :trip="firstTrip")

      CategoryModeToggle(
        :current-id="viewMode"
        :options="toggleOptions"
        :category="category"
        @click="switchMode"
        @update="onUpdateFilter"
      )

      template(v-if="viewMode === VIEW_MODES.cards")
        LazyCategoryCards(:list="gridTripsByAirport")
        h4.text-center.py-4.text-muted.font-weight-normal.d-print-none.mt-2.mb-5(v-if="gridTripsByAirport.length === 0")
          span(:data-i18n="noResultsText.i18nKey") {{ noResultsText.text }}

      template(v-else-if="viewMode === VIEW_MODES.table")
        LazyCategoryTable.mt-n3(
          ref="tripTable"
          :list="tableDataByAirport"
          :fetching="tableDataLoading"
          :loading="tableIsLoading"
          @fetch="getPage"
        )
        h4.text-center.py-4.text-muted.font-weight-normal.d-print-none.mt-2.mb-5(v-if="tableDataByAirport.length === 0 && !tableIsLoading")
          span(:data-i18n="noResultsText.i18nKey") {{ noResultsText.text }}

      template(v-else-if="viewMode === VIEW_MODES.map")
        LazyCategoryMap(
          :map-data="mapData"
          :airport="filters.airport"
          :trip-type="filterTripType"
          @update="onUpdateMapData"
        )
          .worldmap__overlay(v-if="mapDataByAirport.length === 0")
            h4.text-center.py-4.text-white.font-weight-normal.d-print-none.mt-2.mb-5
              span(:data-i18n="noResultsText.i18nKey") {{ noResultsText.text }}
</template>

<script setup>
import { NID_REGEXP } from '@layers/web/constants/regexp'

definePageMeta({
  validate: route => route.params.slug && NID_REGEXP.test(route.params.slug.trim())
})

const { urls: localeURLs } = useLocale()
const route = useRoute()
const router = useRouter()
const { t } = useI18n()
const nuxtApp = useNuxtApp()
const { $sentryCaptureException } = nuxtApp

const localeStore = useLocaleStore()
const calendarStore = useCalendarStore()
const tripStore = useTripStore()
const rootStore = useRootStore()

const { public: { images: imagesConfig } } = useRuntimeConfig()

const { filters, filterTripType } = storeToRefs(calendarStore)

const VIEW_MODES = {
  map: 'map',
  table: 'table',
  cards: 'cards'
}
const DEFAULT_VIEW_MODE = VIEW_MODES.cards

const tableDataDepartures = ref([])
const triptypes = ref([])
const firstTrip = ref({})
const tableDataLoading = ref(true)

const tripTypes = ref([])
const parseTripTypeParam = triptypes => triptypes?.split(',')?.map(tripTypeId => parseInt(tripTypeId)) || []

const { error, data } = await useAsyncData(async () => {
  if (localeStore.isCharter) {
    return navigateTo(`${localeStore.urls.charterpackagescampaign}/gruppresor`)
  }

  calendarStore.resetFilters(false)
  calendarStore.setFromBackupStateQuery(router.currentRoute.value.fullPath)

  const categoryName = router.currentRoute.value.params.slug

  const airport = filters.value.airport
  const type = filterTripType.value
  try {
    const category = await tripStore.fetchCategory(categoryName)

    // Before any filtering is done
    const absoluteFirstTrip = category.trips[0]

    const result = {
      category,
      airports: category?.availible_airports || [],
    }

    tripTypes.value = parseTripTypeParam(router.currentRoute.value.query?.triptypes) || []

    const categoryParams = compileCategoryParams(airport, type, router.currentRoute.value.params?.slug, router.currentRoute.value.query?.triptypes)

    switch (router.currentRoute.value.query.m) {
      case VIEW_MODES.map: {
        const res = await calendarStore.fetchTripTypesMap(categoryParams)

        result.mapData = res
        result.viewMode = VIEW_MODES.map
        break
      }
      case VIEW_MODES.table: {
        const tableres = await tripStore.fetchCalendar({ ...categoryParams, dontCommit: true })

        result.initialTableData = tableres
        result.viewMode = VIEW_MODES.table
        break
      }
      case VIEW_MODES.cards: {
        const cardsRes = await tripStore.fetchCategory(categoryName)

        result.category = cardsRes
        result.viewMode = VIEW_MODES.cards
        break
      }
    }

    localeStore.SET_PAGE_WIDE_TRANSLATIONS(createPageWideTranslation(
      ['calenderHomeDescription', 'siteTitleBrand']
    ))
    rootStore.SET_PAGE_BANNER({
      title: category?.title,
      background: {
        image: {
          url: category?.picture_cloudinary || absoluteFirstTrip.main_picture_cloudinary || imagesConfig.fallbackHeroId
        }
      },
    })

    return result
  } catch (e) {
    throw new Error('Post not found: ' + router.currentRoute.value.fullPath)
  }
})

if (error.value) {
  throw createError({
    message: error.value.message,
    statusCode: 404,
    stack: error.value.stack,
    fatal: true
  })
}
const { category, airports, initialTableData, viewMode: initialViewMode = DEFAULT_VIEW_MODE } = data.value

const image = category?.picture_cloudinary || firstTrip.value?.main_picture_cloudinary || imagesConfig.fallbackHeroId
const desc = parsePreambleToDescription(category?.preamble_html)

useHead({
  title:() => `${category.title}`,
  meta: [
    {
      hid: 'og:url',
      property: 'og:url',
      content:() => `${localeURLs.BASE_URL}${localeURLs.category}/${route.params.slug}`
    },
    {
      hid: 'og:title',
      property: 'og:title',
      content:() => `${category.title} - ${t('siteTitleBrand')}`
    },
    {
      hid: 'og:image',
      property: 'og:image',
      content:() => formatCloudinaryImagePath(image, {
        forceJPG: true,
        isCharter: localeStore.isCharter
      })
    },
    {
      hid: 'og:description',
      name: 'og:description',
      content:() => desc
    },
    {
      hid: 'description',
      name: 'description',
      content:() => desc
    },
  ]
})

const mapData = ref(null)
const viewMode = ref(initialViewMode)

const tableData = reactive({
  departures: initialTableData
})

const setLayout = computed(() => {
  return localeStore.isCharter ? 'charter-empty' : 'banner-default'
})

const toggleOptions = computed(() => {
  return [
    {
      'id': VIEW_MODES.cards,
      'key': 'overview'
    },
    {
      'id': VIEW_MODES.table,
      'key': 'showAsTable'
    },
    {
      'id': VIEW_MODES.map,
      'key': 'showAsMap'
    },
  ]
})

const switchToCalendar = computed(() => {
  return false
})

const nextLink = computed(() => {
  return (tableData.value || {})?.next
})

const departuresList = computed(() => {
  return (tableData.value?.departures || [])
})

/**
 * Triptypes are url params that's included when coming from ex ads
 * The specified triptypes in url should be the ones appearing on top
 */
const sortedGridTrips = computed(() => {
  const tripTypes = [ ...triptypes.value ].reverse()
  const idxedList = (category?.trips || []).map(
    (item, idx) => ({ ...item, idx, highlightIdx: tripTypes.indexOf(item?.id) })
  )
  const gridTripList = idxedList.sort(
    (a, b) => b?.highlightIdx - a?.highlightIdx || a?.idx - b?.idx
  )

  return gridTripList.length === 0
    ? {}
    : gridTripList.reduce((b, a) => ({
      ...b,
      [`trip-${a?.id}`]: a
    }), {})
})

const gridTripsByAirport = computed(() => {
  const filterBy = (item) => {
    switch (filterTripType.value) {
      case 'all': {
        return true
      }
      case 'bus': {
        return !item?.flight_trip
      }
      case 'flight': {
        if (!filters.value.airport || filters.value.airport === 'all') {
          return item?.flight_trip
        }
        const iatas = (item?.avalible_airports || []).map(([iata]) => iata)
        return iatas.includes(filters.value.airport)
      }
      default: {
        return true
      }
    }
  }

  return (Object.values(sortedGridTrips.value) || [])
    .filter(filterBy)
})

const tableDataByAirport = computed(() => {
  const filterBy = (item) => {
    if (item?.flight_trip === 0) {
      return true
    }
    if (!filters.value.airport || filters.value.airport === 'all') {
      return true
    }
    return item?.airport === filters.value.airport || item?.airport_text?.includes(filters.value.airport)
  }
  return (tableDataDepartures.value || [])
    .filter(filterBy)
})

const mapDataByAirport = computed(() => {
  return (mapData.value || [])
    .filter(x => !x?.flight_trip || x?.airport === filters.value.airport)
})

const tableIsLoading = computed(() => {
  return tableDataLoading.value || (departuresList.value.length > 0 && !!nextLink.value)
})

const selectedAirportName = computed(() => {
  const ap = (airports.value || []).find(x => filters.value.airport === x?.iata)
  return ap?.name || null
})

const noResultsText = computed(() => {
  const { title: categoryTitle } = category

  if (selectedAirportName.value) {
    const i18nKey = 'category.noPlannedFlights'
    return {
      i18nKey,
      text: t(i18nKey, {
        category: categoryTitle,
        airport: selectedAirportName.value
      })
    }
  }

  if (!filterTripType.value || filterTripType.value === 'all') {
    const i18nKey = 'tripPage.departures.noDepartures'
    return {
      i18nKey,
      text: `${t(i18nKey)} ${categoryTitle}`
    }
  }

  const i18nKey = 'category.noPlanned'
  return {
    i18nKey,
    text: t(i18nKey, {
      category: categoryTitle,
      triptype: filterTripType.value === 'bus' ? t('busTrips') : t('flightTrips')
    })
  }
})

const specialVideoIntroductions = computed(() => {
  return category?.special_videointroduction?.map(videoIntroduction => ({
    ...videoIntroduction,
    logo: {
      url: videoIntroduction.cloudinary_logo
    },
    media: {
      mediatype: 'video',
      type: videoIntroduction.movie_type,
      location: videoIntroduction.movie_id,
      customThumbnail: {
        url: videoIntroduction.cloudinary_image
      }
    }
  }))
})

watch(() => route.query.triptypes, (v) => {
  triptypes.value = parseTripTypeParam(v) || []
})

firstTrip.value = Object.values(gridTripsByAirport.value)?.[0]

onMounted(() => {
  triptypes.value = parseTripTypeParam(route.query?.triptypes)
  tableDataDepartures.value = [...tableDataDepartures.value, ...(tableData.value?.departures || [])]

  switchMode(route?.query?.m)
})

const switchMode = (mode) => {
  if (!mode) {
    viewMode.value = VIEW_MODES.cards
  } else if (['calendar', 'table'].includes(mode)) {
    viewMode.value = VIEW_MODES.table
  } else if (switchToCalendar.value && viewMode.value === VIEW_MODES.cards) {
    viewMode.value = VIEW_MODES.table
  } else {
    viewMode.value = mode
  }

  try {
    const queryParamsStr = toQueryParams({
      ...router.currentRoute.value?.query || {},
      m: mode || DEFAULT_VIEW_MODE,
      ...(triptypes.value || []).length > 0 ? { triptypes: (Array.isArray(triptypes.value) ? triptypes.value : []).join(',') } : {}
    }, true)
    window.history.replaceState({}, '', router.currentRoute.value.path + queryParamsStr)
  } catch (e) {
    // do nothing
  }

  calendarStore.backupStateQuery()

  if (viewMode.value === VIEW_MODES.table) {
    initCalendar()
  }
}

const compileParams = () => {
  return compileCategoryParams(
    filters.value.airport,
    filterTripType.value,
    router.currentRoute.value?.params?.slug,
    router.currentRoute.value?.query?.triptypes
  )
}

const getPage = async () => {
  if (!nextLink.value) {
    return
  }
  try {
    const { data: result, error } = await apiFetch(nextLink.value, {}, false)
    if (!result || error) {
      category.next = null
      throw new Error('Cannot get next category page')
    }

    tableData.value = result
    tableDataDepartures.value = [...tableDataDepartures.value, ...(result.departures || [])]
  } catch (e) {
    $sentryCaptureException('Cannot get next category page', {
      level: 'error',
      contexts: {
        details: {
          link: nextLink.value,
          exception: e.message
        }
      },
      tags: {
        type: 'UX',
        source: 'category'
      }
    })
    category.next = null
  }
}

const initCalendar = async () => {
  tableDataLoading.value = true
  if (tableData.value && departuresList.value.length > 0) {
    await getPage(nextLink.value)
    tableDataLoading.value = false
    return
  }

  const res = await tripStore.fetchCalendar({
    ...compileParams()
  })

  tableData.value = res
  tableDataDepartures.value = [...tableDataDepartures.value, ...(res.departures || [])]
  tableDataLoading.value = false
}

const onUpdateFilter = () => {
  tableData.value = { departures: [] }
  tableDataDepartures.value = []
  switchMode(viewMode.value)
}

const onUpdateMapData = (payload) => {
  mapData.value = payload
}
</script>

<style lang="scss">
/*! purgecss start ignore */
.category-preamble {
  h1, h2, h3 {
    // Chunk of HTML from Filemaker, hence no helper classes
    font-family: $serif-font;
    margin-bottom: 1rem;
  }
}
/*! purgecss end ignore */
</style>
